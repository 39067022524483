import React, { useCallback } from "react";
import { trackUserAction } from '../../utils/ClarityProvider';

const FilterBadge = ({ label, isActive, onClick, category = 'general' }) => {
  const handleClick = useCallback(() => {
    trackUserAction('filter_badge_click', {
      filter: label,
      category,
      state: !isActive ? 'active' : 'inactive'
    });
    onClick();
  }, [label, isActive, onClick, category]);

  return (
    <button
      onClick={handleClick}
      className={`px-3 py-1 rounded-full text-sm font-medium transition-all ${
        isActive
          ? "bg-[#0e9065] text-white"
          : "bg-gray-200 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-600"
      }`}
      aria-pressed={isActive}
      role="switch"
    >
      {label}
    </button>
  );
};

export default React.memo(FilterBadge);
